<template>
  <div class="reporting-navigation-header">
    <div class="reporting-navigation-header__left">
      <div class="reporting-navigation-header__info">
        <div class="reporting-navigation-header__items">
          <!-- <device type="desktop"> -->
          <app-breadcrumb :breadcrumbs="breadcrumbs" />
        <!-- </device> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppBreadcrumb from '@/components/AppBreadcrumb';

export default {
  name: 'ReportingNavigationHeader',
  components: { AppBreadcrumb },
  props: {
    breadcrumbs: {
      type: Array,
      default() {
        return [];
      }
    }
  }
};
</script>

<style lang="scss">
.reporting-navigation-header {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  background: $--clb-color-primary__white;

  .reporting-navigation-breadcrumb {
    margin-bottom: $--clb-space-5;
  }

  &__left {
    display: flex;
    align-items: flex-end;
    position: relative;
    width: 100%;
    white-space: nowrap;
  }

  &__info {
    width: 100%;
  }
}

@media (min-width: $--sm) {
  .reporting-navigation-header {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    &__left {
      flex: 2;
      overflow: hidden;
    }
  }
}
</style>
