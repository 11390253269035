<template>
  <div class="group-filter__groupby">
    <el-checkbox-group :min="min" :value="value" @input="$emit('input', $event)">
      <el-checkbox
        v-for="(label, subid) in subids"
        :id="`groupby__subid-${subid}`"
        :key="subid"
        :label="subid"
        border>
        {{ label | capitalizeAll }}
      </el-checkbox>
    </el-checkbox-group>
  </div>
</template>
<script>
export default {
  name: 'GroupFilter',
  props: {
    value: {
      type: Array,
      required: true
    },
    subids: {
      type: Object,
      required: true
    },
    min: {
      type: Number,
      default: 0
    }
  }
};
</script>

<style lang="scss">
.group-filter {
  &__groupby {
    .el-checkbox-group {
      display: flex;
      flex-wrap: wrap;
      max-width: 250px;

      .el-checkbox {
        zoom: 1;
        margin: 0 $--clb-space-2 $--clb-space-2 0;
        border-radius: 100px;
        border-radius: 20px;
        transition: 0.2s ease-in;
        padding: 9px 18px 9px 8px;
        line-height: 22px;
        background: $--clb-color-primary__white;

        &__inner {
          display: none;
        }

        &:hover,
        &.is-checked {
          border-color: $--jb-dark-primary-color;
          transition: 0.2s ease-out;

          &_label {
            color: $--jb-dark-primary-color;
          }
        }

        &.is-checked,
        &:active {
          border-color: $--jb-light-primary-color;
          background: $--color-light;

          &__label {
            color: $--jb-light-primary-color;
          }
        }

        &:active {
          transform: translateY(0);
        }
      }
    }
  }
}

@media (min-width: $--xs) {
  .group-filter {
    &__groupby {
      .el-checkbox-group {
        max-width: none;
      }
    }
  }
}
</style>
