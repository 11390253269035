<template>
  <el-breadcrumb separator=">" class="campaign-navigation-breadcrumb">
    <el-breadcrumb-item v-for="(breadcrumb, i) in breadcrumbs" :key="breadcrumb.to" class="campaign-navigation-breadcrumb__item">
      <router-link v-if="breadcrumb.to && i != (breadcrumbs.length - 1)" :to="breadcrumb.to">
        {{ breadcrumb.name }}
      </router-link>
      <span v-else>{{ breadcrumb.name }}</span>
    </el-breadcrumb-item>
  </el-breadcrumb>
</template>

<script>
export default {
  name: 'AppBreadcrumb',
  props: {
    breadcrumbs: {
      type: Array,
      default() {
        return [];
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.campaign-navigation-breadcrumb {
  overflow: hidden;
  min-height: 34px;
}

.campaign-navigation-breadcrumb__item {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  display: inline-block;
}
</style>
