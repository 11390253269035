<template>
  <div class="filters-summary">
    <template v-for="(value, key) in filters">
      <span :key="key" class="filters-summary__item"><strong>{{ key }}:</strong> {{ value }} </span><wbr :key="`wbr-${key}`">
    </template>
  </div>
</template>

<script>
import { DATE_PRESETS } from '@/constants/dates';

const FILTERS_NAME = {
  campaign_id: 'Campaign',
  funnel_id: 'Funnel',
  c1: 'C1',
  c2: 'C2',
  c3: 'C3'
};

export default {
  name: 'FiltersSummary',
  props: {
    reportOptions: {
      type: Object,
      required: true
    }
  },
  computed: {
    dateFilterSummary() {
      if (!this.reportOptions.filter) { return '-'; }
      if (this.reportOptions.filter.date) {
        return this.reportOptions.filter.date;
      }
      if (this.reportOptions.dateRange === 'CUSTOM') {
        return this.reportOptions.startDate === this.reportOptions.endDate
          ? this.reportOptions.startDate
          : `${this.reportOptions.startDate} to ${this.reportOptions.endDate}`;
      } else {
        return DATE_PRESETS[this.reportOptions.dateRange];
      }
    },
    filters() {
      const filters = {};

      if (this.dateFilterSummary) {
        filters.Date = this.dateFilterSummary;
      }

      if (this.reportOptions && this.reportOptions.filter) {
        Object.keys(this.reportOptions.filter).forEach(key => {
          if (key !== 'date') {
            filters[this.getFilterName(key)] = this.getFilterValue(this.reportOptions.filter[key]);
          }
        });
      }

      return filters;
    }
  },
  methods: {
    getFilterName(key) {
      return FILTERS_NAME[key];
    },
    getFilterValue(value) {
      return value || '-';
    }
  }
};
</script>

<style lang="scss">
.filters-summary {
  font-size: $--clb-font-size-xs;

  &__item {
    white-space: nowrap;
    max-width: 150px;
    text-overflow: ellipsis;
    padding-right: $--clb-space-2;

    strong {
      color: $--clb-color__headings;
      font-weight: 600;
    }
  }
}
</style>
