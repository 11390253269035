<template>
  <div class="campaign-affiliate-reporting-daily">
    <portal to="app-right-sidebar-title">
      <reporting-navigation-header :breadcrumbs="breadcrumbs" />
    </portal>
    <div class="affiliate-reporting-body">
      <div class="daily-breakdown-header">
        <div class="daily-breakdown-title">
          <div class="daily-breakdown-title__group">
            <h3> Daily Breakdown </h3>
            <filters-summary :report-options="reportOptions" />
          </div>
          <div class="daily-breakdown-title__btn-group">
            <affiliate-reporting-export-button :disabled="!reportData.length" :loading="loadingCsvFile" @download-csv="downloadReport" />
            <app-button
              v-if="showBackBtn"
              class="daily-breakdown-title__btn-group-back"
              @click="handleActionBack">
              <i class="el-icon-back hidden-sm-and-down" /> Back
            </app-button>
          </div>
        </div>
        <div class="campaign-affiliate-reporting-metrics__filters">
          <div class="campaign-affiliate-reporting-metrics__filters-group">
            <h5>Breakdown</h5>
            <period-type-filter v-model="periodType" :period-types="periodTypes" />
          </div>
          <div class="campaign-affiliate-reporting-metrics__filters-group">
            <h5>Group By</h5>
            <group-filter v-model="filterGroups" :subids="subids" />
          </div>
        </div>
      </div>
      <div v-if="reportData" class="daily-breakdown-reports">
        <affiliate-reporting-table
          :table-data="reportData"
          :columns="reportColumns"
          :table-summary="reportSummary"
          :pagination="pagination"
          :sticky-app-header-height="60"
          :default-sort="sortState"
          :loading="fetchingReport"
          :skeleton-rows="5"
          sticky-sink-id="affiliate-daily-sink"
          portal-to="app-right-sidebar-header"
          @cell-click="handleDrilldownClick"
          @page-change="handlePageChange"
          @on-sort="handleSort">
          <el-table-column
            v-for="(column, index) in reportColumns"
            :key="column"
            :sort-orders="sortOrders"
            :prop="column"
            :label="columnWhiteListMap[column].label"
            :min-width="columnWhiteListMap[column].minWidth"
            :align="columnWhiteListMap[column].align"
            :class-name="columnClassName"
            sortable="custom">
            <template slot-scope="scope">
              <skeleton-box
                v-if="fetchingReport"
                :width="`${columnWhiteListMap[column].minWidth * 0.7}px`"
                :height="'12px'"
                :style="{ float: columnWhiteListMap[column].align || 'left' }" />
              <span v-else :class="reportTableColumnClass(column)">
                <i v-if="index === 0 && hasGroupBy" class="el-icon-arrow-right" />
                {{ getFormattedColumn(scope.row, column) }}
              </span>
            </template>
          </el-table-column>
        </affiliate-reporting-table>
      </div>
    </div>
  </div>
</template>

<script>
import { TableColumn } from 'element-ui';
import AffiliateReportingExportButton from '@/components/affiliate/reporting/AffiliateReportingExportButton';
import AffiliateReportingTable from '@/components/tables/AffiliateReportingTable';
import AffiliateReportMixin from '@/mixins/AffiliateReportMixin';
import AppButton from '@/components/AppButton';
import FiltersSummary from '@/components/filters/FiltersSummary';
import GroupFilter from '@/components/filters/GroupFilter';
import PeriodTypeFilter from '@/components/filters/PeriodTypeFilter';
import ReportingNavigationHeader from '@/components/campaigns/ReportingNavigationHeader';
import SkeletonBox from '@/components/skeletons/SkeletonBox';

export default {
  name: 'CampaignAffiliateReportingDaily',
  components: {
    AffiliateReportingExportButton,
    AffiliateReportingTable,
    ReportingNavigationHeader,
    GroupFilter,
    PeriodTypeFilter,
    'el-table-column': TableColumn,
    SkeletonBox,
    AppButton,
    FiltersSummary
  },
  mixins: [AffiliateReportMixin],
  props: {
    sortOrders: {
      type: Array,
      default: () => ['descending', 'ascending', null]
    }
  },
  data() {
    return {
      breadcrumbs: [{ name: 'Reporting' }, { name: 'Daily Breakdowns' }],
      reportSubType: this.$route.meta.reportSubType,
      excludedColumns: Object.freeze([])
    };
  }
};
</script>
<style lang="scss">
.campaign-affiliate-reporting-daily {
  margin-top: -$--clb-space-2;

  h3 {
    margin: 0;
  }

  h5 {
    margin: $--clb-layout-1 0 $--clb-space-2 0;
    font-size: $--clb-font-size-base;
  }

  .filters-summary {
    padding-top: $--clb-space-1;
  }

  .affiliate-reporting-body {
    padding: 0 $--clb-mobile-padding;

    .daily-breakdown-header {
      .daily-breakdown-title {
        margin-top: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &__group {
          display: flex;
          flex-direction: column;
          flex: 7;
        }

        &__btn-group {
          display: flex;
          flex: 3;
          justify-content: flex-end;
        }

        &__btn-group-back {
          background: $--clb-color-secondary__dark;
          height: 40px;
          margin-left: $--clb-layout-1 - 1;
        }
      }
    }

    .campaign-affiliate-reporting-metrics__filters {
      margin-top: $--clb-layout-2;
    }

    .breakdown-items {
      margin-top: $--clb-space-3;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;

      .item {
        margin: 0 auto 0 0;

        .item-title {
          color: gray;
          padding: 0 $--clb-layout-4 0 0;
          margin: 0 0 $--clb-space-1 0;
        }

        .item-content {
          font-weight: $--clb-font-weight__semi-bold;
        }
      }
    }
  }

  .daily-breakdown-reports {
    margin-top: $--clb-space-5;
  }
}

@media (min-width: $--sm) {
  .affiliate-reporting-body {
    padding: 0 $--clb-layout-2;
  }
}
</style>
